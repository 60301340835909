import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouteReuseStrategy} from "@angular/router";
import {IonicModule, IonicRouteStrategy} from "@ionic/angular";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {environment} from "../environments/environment";
import {DatePipe} from "@angular/common";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {AppVmsModule} from "@qid/entities/app-vms";
import {NgOtpInputModule} from "ng-otp-input";
import {SexyModule} from "@qid/sexy";
import {ENVIRONMENT} from "@qid/env";
import {
  AccountAttributeModule,
  AuthenticationModule,
  CollectionTemplateModule, DocumentAccessModule,
  IdModule,
  metaReducers,
  OrganisationService,
  PlanPurchaseModule,
  RestService,
  ScannerModule,
  SidModule,
  SignatureModule,
  UserModule,
  UserService
} from "@qid/common";
import {ShareModule} from "@qid/common";
import {FieldModule} from "@qid/field";
import {FamilyMemberModule} from "@qid/entities/family-member";
import {CoreModule, DisplayService} from "@qid/core";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {GatewayModule} from "@qid/gateway";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: "ios"}),
    AppRoutingModule,
    NgOtpInputModule,
    HttpClientModule,
    UserModule,
    ShareModule,
    IdModule,
    SidModule,
    AppVmsModule,
    CollectionTemplateModule,
    ScannerModule,
    FieldModule,
    SignatureModule,
    FamilyMemberModule,
    StoreModule.forRoot({}, {metaReducers: metaReducers}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    CoreModule.forRoot({environment: environment}),
    SexyModule,
    DocumentAccessModule,
    AuthenticationModule,
    GatewayModule,
    PlanPurchaseModule,
    AccountAttributeModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    UserService,
    RestService,
    DisplayService,
    DatePipe,
    OrganisationService,
    {provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig},
    {provide: ENVIRONMENT, useValue: environment}
  ],
  bootstrap:
    [AppComponent],
  exports:
    [],
})

export class AppModule {
}
