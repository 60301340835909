import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AbsoluteValue} from "../util/AbsoluteValue";
import {RadiusDirective} from "./radius.directive";

@Directive({selector: '[sxRadiusOnly]'})
export class RadiusOnlyDirective implements OnInit {

    @Input() sxRadiusOnly: 'small' | 'medium' | 'large' | string;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        try {
          this.el.nativeElement.style.borderRadius = AbsoluteValue.getSplitValues(this.sxRadiusOnly, RadiusDirective.themeValues);
        } catch (e) {
            console.error("SX RADIUS ONLY PROPERTY", e)
        }
    }


}
