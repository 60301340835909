<div sxPadding="medium" sxCol sxGap="10px" *ngIf="!isProfileSlide">

<!--  <div sxMargin="large 0 0">-->
<!--    <p sxMargin="0 10px" sxTextSize="medium" sxColor="two" sxTextWeight="bold">One-time</p>-->
<!--    <h1 sxMargin="0 10px">Verification</h1>-->
<!--  </div>-->

  <div *ngIf="!faceId && !isFetchingFaceId">
    <div sxWidth="full" sxBackground="light" sxRadius="medium">
      <face-capture #faceCapture (onCapture)="onFaceCaptured($event)"></face-capture>
    </div>
  </div>


  <div *ngIf="faceId && !isFetchingFaceId" sxPadding="medium" sxCol sxAlign="center" sxBackground="light"
       sxDistribution="center" sxRadius="medium">

    <avatar-round sxMargin="10px 0 20px" [size]="150" [isEditMode]="true" bubbleIcon="create" (onBubbleClick)="onFaceChangeClicked()" [imageUrl]="faceId.imageUrl"></avatar-round>

    <div class="digi-wrapper" sxRadiusOnly="medium" sxPadding="medium" sxBorderWidth="1px" sxBorderColor="one">

      <div class="next-step-label">Next Step</div>

      <div sxWidth="full" sxCol sxGap="5px" sxAlign="center" sxPadding="medium">

        <img class="digilocker-logo" sxWidth="100px" src="assets/digilocker-logo.webp"/>
        <h1 sxMargin="0" sxTextSize="medium" sxColor="three" >Verification with DigiLocker</h1>
      </div>

      <p sxTextSize="small" sxMargin="0 0 20px" sxTextAlign="center" sxColor="two">Your name, age, gender, and address will be verified via DigiLocker. Only these details may be extracted, and no identity documents, such as Aadhaar or PAN, will be stored</p>

      <div *ngIf="idVerificationStatus == null" >
        <super-button sxWidth="full" (click)="openDigilockerLink()" iconEnd="arrow-forward-outline">
          Continue
        </super-button>
      </div>

      <div *ngIf="idVerificationStatus == 'started'" sxCenterize="col">
        <p sxColor="two">Authenticating with Digilocker</p>
        <ion-skeleton-text
          sxRadius="medium" sxMargin="0 0 20px"
          sxWidth="full" sxHeight="30px" animated="true">
        </ion-skeleton-text>
        <super-button sxWidth="full" type="outline">Cancel</super-button>
      </div>

      <div *ngIf="idVerificationStatus == 'failed'">
        <p>Digilocker Failed</p>
      </div>


      <div *ngIf="idVerificationStatus == 'success'" sxCenterize="col">
        <p sxColor="two">Authenticated ✅</p>
      </div>

    </div>

  </div>



</div>

<div *ngIf="isProfileSlide"
     sxMatchParent sxRadius="medium" sxPadding="medium" sxCol sxDistribution="center">

  <div sxCol sxAlign="center" sxGap="10px" *ngIf="isCreatingProfile">
    <ion-skeleton-text sxCircle="100px" animated="true"></ion-skeleton-text>
    <ion-skeleton-text sxRadius="medium" sxWidth="150px" sxHeight="30px" animated="true"></ion-skeleton-text>
    <ion-skeleton-text sxRadius="medium" sxWidth="100px" sxHeight="20px" animated="true"></ion-skeleton-text>

    <div sxBackground="light" sxRadius="medium" sxPadding="medium" sxBorderColor="one" sxBorderWidth="1px" sxRow
         sxAlign="center" sxGap="10px">
      <p sxMargin="0" sxTextSize="small">Creating Verified Profile</p>
      <ion-spinner></ion-spinner>
    </div>

  </div>

  <div *ngIf="profile$ | async as profile">

    <share-account-profile-view (onShareConfirmed)="onShareConfirmed($event)" sxWidth="full" sxHeight="full" [profile]="profile"></share-account-profile-view>

  </div>


</div>
