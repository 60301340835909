import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CollectionTemplate, DocumentAccessService, Organisation, RestResponse} from "@qid/common";
import {Participant, ParticipantRoles, Share, ShareService} from "@qid/common";
import {AccountType, DisplayService} from "@qid/core";
import {ModalController} from "@ionic/angular";
import {AddFamilyMemberComponent} from "@qid/entities/family-member";
import {GatewayDocumentSubmissionUtil} from "./gateway-document-submission.util";

@Component({
  selector: 'gateway-id-submission',
  templateUrl: './gateway-document-submission.component.html',
  styleUrl: './gateway-document-submission.component.scss',
})
export class GatewayDocumentSubmissionComponent {

  @Input() share: Share;
  @Input() template: CollectionTemplate;
  @Input() organisation: Organisation;

  @Output() onSubmit: EventEmitter<Share> = new EventEmitter<Share>()

  submitNote: string;


  constructor(
    private displayService: DisplayService,
    private modalController: ModalController,
    private documentAccessService: DocumentAccessService,
    private shareService: ShareService
  ) {
  }


  onIdSubmitClicked = async () => {
    try {

      for (let participant of this.share.participants) {

        if (participant.roles.indexOf(ParticipantRoles.sharer) < 0) {
          continue;
        }

        GatewayDocumentSubmissionUtil.validateParticipantDocumentFields(participant)

        participant.accountSnapshot = {
          name: participant.account?.name,
          email: participant.account?.email,
          phoneNumber: participant.account?.phoneNumber,
          displayPictureUrl: participant.account?.displayPictureUrl
        }

        participant.analytics = GatewayDocumentSubmissionUtil.getParticipantAnalytics(participant)

        this.share.masterKeyString = participant.documentsAccess
          .flatMap(access => access.documentData?.masterKeyString ?? '')
          .join(' ')

        this.share.masterKeyString += participant?.accountSnapshot?.name

      }

      await this.createSharedDocuments();

      await this.updateShare();

      this.onSubmit.emit(this.share)

    } catch (e) {
      this.submitNote = e && e.message ? e.message : e;
    }
  };


  public async addParticipant() {
    const modal = await this.modalController.create({
      component: AddFamilyMemberComponent,
      componentProps: {
        isEmailRequired: this.template.isParticipantEmailRequired,
        isPhoneRequired: this.template.isParticipantPhoneRequired
      },
      animated: false
    });

    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data && res.data.member) {

      if (this.share.participants.find(participant =>
        participant.account_id === res.data.member?.user?._id
      )) {
        this.displayService.alert("Member already added");
        return;
      }

      let participant = new Participant();
      participant.account_id = res.data.member?.user?._id;
      participant.memberId = res.data.member?._id;
      participant.account = res.data.member?.user;
      participant.accountType = AccountType.user;
      participant.roles = [ParticipantRoles.sharer];
      this.share.participants.push(participant);

    }

  }

  removeParticipant(index: any) {
    this.share.participants.splice(index, 1);
  }


  async createSharedDocuments() {
    for (let participant of this.share.participants) {
      if (participant.roles.indexOf(ParticipantRoles.sharer) < 0) {
        continue;
      }
      for (let access of participant.documentsAccess) {
        await this.createDocumentAccess(access._id);
      }
    }
  }

  async createDocumentAccess(access_id: string) {

    const res = await this.documentAccessService.grantDocumentAccess(
      access_id, this.organisation?._id, AccountType.organisation
    );
    if (res && res.data) {
      return res.data
    }

    if (!res || res.errorMessage) {
      throw new Error("Documents could not be shared")
    }

  }

  isParticipant(participant: Participant) {
    if (!participant.roles) {
      return true;
    }
    return participant.roles.indexOf(ParticipantRoles.sharer) > -1
  }

  async updateShare() {

    let participants = this.share.participants.map((participant) => {
      return {
        ...participant, documents: [], optionalIds: []
      }
    })

    const restResponse: RestResponse<Share> = await this.shareService.update(
      this.share._id, {
        participants: participants,
        masterKeyString: this.share.masterKeyString,
        updateRemark: "Documents Submitted"
      })

    if (restResponse && restResponse.data) {
      this.share = restResponse.data;
      return;
    }

    if (!restResponse) {
      throw new Error("Something went wrong while updating documents in transaction");
    }
    if (restResponse.errorMessage) {
      throw restResponse.errorMessage;
    }

  }

}
