import {Component, OnInit} from '@angular/core';
import {AccountProfileApi} from "../../services/account-profile.api";
import {AccountProfileFacade} from "../../state/account-profile.facade";
import {ModalController} from "@ionic/angular";
import {AccountAttributeType, ListAccountAttributeComponent} from "../../../account-attribute";
import {AccountProfile} from "../../model/account-profile.model";
import {Observable} from 'rxjs';
import {AccountProfileUtil} from "../../util/account-profile.util";
import {DisplayService} from "@qid/core";

@Component({
  selector: 'oneqid-nx-edit-account-profile',
  templateUrl: './edit-account-profile.page.html',
  styleUrls: ['./edit-account-profile.page.scss'],
})
export class EditAccountProfilePage implements OnInit {

  public profile$: Observable<AccountProfile> = this.accountProfileFacade.profile$

  constructor(private accountProfileApi: AccountProfileApi,
              private accountProfileFacade: AccountProfileFacade,
              private modalController: ModalController,
              private displayService: DisplayService
  ) {
  }

  ngOnInit() {
  }

  ionViewDidEnter() {

  }

  async readProfile() {
  }

  async createProfile() {
    const res = await this.accountProfileApi.create()
  }

  async onChangeAttributeClicked(attributeType: AccountAttributeType, label: string) {
    const modal = await this.modalController.create({
      component: ListAccountAttributeComponent,
      componentProps: {
        label: label,
        attributeTypeFilter: attributeType
      }
    })

    modal.present();
  }


  updateProfilePhoto = async (imageRes: any) => {
    try {
      await this.accountProfileFacade.updateDisplayPicture(imageRes.file)
    } catch (e) {
      this.displayService.toast({
        message: e,
        color : 'danger'
      })
    }

  }

  protected readonly AccountAttributeType = AccountAttributeType;
  protected readonly getAttributeByType = AccountProfileUtil.getAttributeByType;

  protected readonly AccountProfileUtil = AccountProfileUtil;
}
