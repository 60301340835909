import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {AccountAttributeFacade, AccountAttributeStore} from "./state/account-attribute.facade";
import {EffectsModule} from "@ngrx/effects";
import {AccountAttributeEffects} from "./state/account-attribute.effects";
import {AccountAttributeApi} from "./services/account-attribute.api";

@NgModule({
  imports: [
    StoreModule.forFeature(
      AccountAttributeStore.featureKey,
      AccountAttributeStore.reducer.recordReducer
    ),
    EffectsModule.forFeature([AccountAttributeEffects]),
  ],
  providers: [
    AccountAttributeApi,
    AccountAttributeFacade
  ],
  exports: []
})
export class AccountAttributeModule {

}
