import {UpdateLog} from "./update-log.model";

export class MetaData {
  _id: string;
  createTime: Date;
  createdBy: string;
  lastUpdateTime: Date;
  updateLogs : UpdateLog[];
  deleteTime: Date;
}
