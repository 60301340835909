import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {exhaustMap, from, map, of} from 'rxjs';
import {AccountAttributeApi} from "../services/account-attribute.api";
import {AccountAttributeStore} from "./account-attribute.facade";
import {AccountAttribute} from "../model/account-attribute.model";


@Injectable()
export class AccountAttributeEffects {
  private actions$ = inject(Actions);
  private apiService = inject(AccountAttributeApi)
  private ACTIONS = AccountAttributeStore.actions;

  addAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.addAccount),
      exhaustMap((props) =>
        of(this.ACTIONS.loadRecordsForAccount({
          account_id: props.accountBasedEntity.account_id,
          accountType: props.accountBasedEntity.accountType
        }))
      )
    )
  );

  loadRecordsForAccount = createEffect(() =>
    this.actions$.pipe(
      ofType(this.ACTIONS.loadRecordsForAccount),
      exhaustMap((props) =>
        from(this.apiService.readAll()).pipe(
          map(apiResponse => {
            const accountAttributes: AccountAttribute[] = apiResponse && apiResponse.data ? apiResponse.data : null
            return this.ACTIONS.loadRecordsForAccountSuccess({
              records: accountAttributes,
              account_id: props.account_id
            })
          })
        )
      )
    )
  );


}
