import {Component, Input, OnInit} from '@angular/core';
import {ItemReorderEventDetail, ModalController} from '@ionic/angular';
import {Field} from "@qid/field";
import {DisplayService} from '@qid/core';
import {IdField} from "../../../id";
import {SID, SidSelectorComponent} from "../../../sid";


@Component({
  selector: 'template-id-setter',
  templateUrl: './template-id-setter.component.html',
  styleUrls: ['./template-id-setter.component.scss'],
})
export class TemplateIdSetterComponent implements OnInit {

  @Input() acceptedIds: Field<IdField>[] = [];
  @Input() pageMode: boolean = false;

  public selectedSids: any[];

  constructor(
    private modalController: ModalController,
    private displayService: DisplayService
  ) {
  }

  ngOnInit() {
    this.selectedSids = this.acceptedIds.map(field => {
      return {_id: field?.data?.sidId, name: field?.name}
    })
  }


  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.acceptedIds = ev.detail.complete(this.acceptedIds);
  }

  addSid(sid: SID) {
    let field = new Field<IdField>(sid.name);
    field.isEnabled = true;
    field.data = {sidId: sid._id};
    field.isEdit = sid.isSystemApproved
    this.acceptedIds.push(field);
    this.selectedSids = this.acceptedIds.map(field => {
      return {_id: field?.data?.sidId, name: field?.name}
    })
  }


  removeId(index: number) {
    // if (this.acceptedIds.length === 1) {
    //   return this.displayService.toast({message: "Please add one or more IDs to use the form.", color: "warning"})
    // }
    this.acceptedIds.splice(index, 1)
    this.selectedSids = this.acceptedIds.map(field => {
      return {_id: field?.data?.sidId, name: field?.name}
    })
  }

  async openSidSelector() {
    const modal = await this.modalController.create({
      component: SidSelectorComponent,
      componentProps: {
        pageMode: true,
        selectedSids: this.acceptedIds.map(field => {
          return {_id: field?.data?.sidId, name: field?.name}
        })
      }
    })
    await modal.present();
    const res = await modal.onDidDismiss();

    if (res?.data?.selectedSids) {
      const alreadyAdded = this.acceptedIds.map(id => id.data.sidId)
      res.data.selectedSids.forEach((sid: SID) => {
        if (alreadyAdded.indexOf(sid._id) < 0) {
          this.addSid(sid);
        }
      })
    }
  }

  save() {
    this.modalController.dismiss({
      acceptedIds: this.acceptedIds,
    });
  }

  cancel() {
    this.modalController.dismiss();
  }

}
