<div sxPadding="medium" *ngIf="profile" sxCol sxAlign="center" sxGap="10px">

  <div sxCol sxAlign="center" sxGap="10px">
    <p sxMargin="0" sxColor="two" sxTextSize="small">Share verified details with</p>
    <p sxMargin="0" sxTextSize="medium">Taj by Vivanta - Surajkund</p>
  </div>


  <div class="profile-view-wrapper"
       sxCol sxAlign="center"
       sxWidth="full" sxHeight="full" sxPadding="medium">


    <avatar-round sxMargin="-50px 0 0" [size]="80"
                  [imageUrl]="profile.displayPictureUrl"></avatar-round>

    <div *ngIf="profile.name?.verificationMethod"
         sxRadius="50px" sxBorderWidth="1px" sxBorderColor="one" sxPadding="small medium">
      <p sxMargin="0" sxColor="three" sxTextSize="small">{{profile?.name?.verificationMethod}}</p>
    </div>

    <div sxMargin="10px auto" sxRow sxAlign="center" sxGap="5px">
      <div sxWidth="15px"></div>
      <h1 sxMargin="0" sxColor="three" sxTextSize="large">{{profile?.name?.value}}</h1>
      <img sxWidth="25px" sxHeight="25px" src="assets/blue-tick.png">
    </div>

    <div class="share-detail-wrapper" sxBackground="light" sxWidth="full">

      <div class="profile-attribute">
        <p>Age</p>
        <p>{{age}}</p>
      </div>
      <div class="profile-attribute">
        <p>Gender</p>
        <p>{{gender}}</p>
      </div>
      <div class="profile-attribute">
        <p>Address</p>
        <p>{{address}}</p>
      </div>

      <div sxWidth="full" sxRow sxHeight="30px" sxDistribution="spaceBetween" sxBackground="light">
        <div sxBackground="base-shade" sxCircle="30px" sxMargin="0 0 0 -15px"></div>
        <div sxWidth="full" sxHeight="50%" sxBorderColor="one" sxBorderWidth="0 0 1px"></div>
        <div sxBackground="base-shade" sxCircle="30px" sxMargin="0 -15px 0 0"></div>
      </div>
      <div class="digilocker-label" sxWidth="full" sxBackground="light" sxHeight="30px" sxRadius="0 0 20px 20px" sxRow sxGap="5px" sxDistribution="center">
        <ion-icon name="shield-checkmark-outline"></ion-icon> <p sxMargin="0" sxTextSize="small">DigiLocker verified</p>
      </div>

    </div>


  </div>

  <super-button (click)="onShareClicked()" sxWidth="full" radius="20px" iconEnd="arrow-forward-outline">Share</super-button>


</div>
