export enum DateRange {
  'TODAY' = 'Today',
  'WEEK' = 'Week',
  'THIS_MONTH' = 'This Month',
  'LAST_30_D' = 'Last 30 Days',
  'PREVIOUS_MONTH' = 'Previous Month',
  'LAST_3_MONTH' = 'Last 3 Months',
  'CURRENT_YEAR' = 'Current Year',
  'LAST_12_MONTHS' = 'LAST_12_MONTHS',
  'CUSTOM' = 'Custom Date',
}
