import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AccountProfileUtil} from "../../util/account-profile.util";
import {AccountProfile} from "../../model/account-profile.model";
import {AccountAttributeType} from "../../../account-attribute";
import {Router} from "@angular/router";
import {Store} from '@ngrx/store';
import {AuthSelectors} from "../../../authentication";
import {map, Observable} from 'rxjs';
import {DateUtil} from "@qid/core";
import {AccountAttribute} from "../../../account-attribute/model/account-attribute.model";
import {Address} from '../../../../interface/address.inteface';


@Component({
  selector: 'share-account-profile-view',
  templateUrl: './share-account-profile-view.component.html',
  styleUrl: './share-account-profile-view.component.scss',
})
export class ShareAccountProfileViewComponent {

  @Input() profile: AccountProfile = null

  @Output() onShareConfirmed: EventEmitter<any> = new EventEmitter<any>()

  public activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount);

  public isOwner$: Observable<boolean> = this.activeAccount$.pipe(
    map(activeAccount => {
      // Null checks for both profile and activeAccount
      if (!this.profile || !activeAccount) {
        return false;
      }
      return this.profile.account_id === activeAccount._id;
    })
  );

  public age: number;
  public gender: string;
  public address: string;

  constructor(private router: Router,
              private store: Store
  ) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setAge();
      this.setGender();
      this.setAddress();
    }, 1000)
  }

  protected readonly AccountProfileUtil = AccountProfileUtil;
  protected readonly AccountAttributeType = AccountAttributeType;


  async editProfileClicked() {
    this.router.navigateByUrl('edit-account-profile');
  }

  setAge() {
    const dob: AccountAttribute = this.profile.defaultAccountAttributes.find(att => att.attributeType == AccountAttributeType.dob)
    this.age = DateUtil.getAge(dob.value)
  }

  setGender() {
    const genderAttribute: AccountAttribute = this.profile.defaultAccountAttributes.find(att => att.attributeType == AccountAttributeType.gender)
    switch (genderAttribute.value) {
      case "M" :
        this.gender = "Male";
        break;
      case "F" :
        this.gender = "Female";
        break;
      default :
        this.gender = genderAttribute.value;
    }
  }

  setAddress() {
    const attribute: AccountAttribute = this.profile.defaultAccountAttributes.find(att => att.attributeType == AccountAttributeType.address)
    const address: Address = attribute.value;
    this.address = `${address.street || ''} ${address.dist},${address.country}`
  }

  onShareClicked() {
    this.onShareConfirmed.emit(
      {
        attributes: [
          {label: 'Name', value: this.profile.name},
          {label: 'Age', value: this.age},
          {label: 'Gender', value: this.gender},
          {label: 'Address', value: this.address}
        ],
        isVerified: true,
        verificationMethod: "DigiLocker"
      }
    )
  }

}
