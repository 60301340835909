import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ValidationUtil} from "@qid/core";
import {Store} from "@ngrx/store";
import {User} from "../../../user/lib/model/User";
import {CreateUserDto} from "../../../user/lib/dto/create-user.dto";
import {UserService} from "../../../user/lib/services/user.service";
import {OtpVerifier} from "../../../user/lib/model/OtpVerifier";
import {OtpChannel} from "../../../user/lib/enum/OtpChannel";
import {OtpService, UsersSelectors} from "../../../user";
import {AuthenticationService} from "../../index";
import {AlertController} from "@ionic/angular";
import {AccountProfile, AccountProfileFacade} from "../../../account-profile";


@Component({
  selector: "quick-signup",
  templateUrl: "./quick-signup.component.html",
  styleUrls: ["./quick-signup.component.scss"]
})
export class QuickSignupComponent implements OnInit {

  @Input() defaultEmail: string = null
  @Input() defaultName: string = null

  @Input() isOtpVerification: boolean;
  @Input() isEmailRequired: boolean;
  @Input() isPhoneRequired: boolean;

  @Output() onLogin: EventEmitter<User> = new EventEmitter<User>();

  public createUserDto: CreateUserDto = new CreateUserDto();
  public authenticatedUser$ = this.store.select(UsersSelectors.selectAuthenticatedUser);
  public accountProfile$ = this.accountProfileFacade.profile$

  public slides = {
    loggedIn: "loggedIn",
    signIn: "signIn",
    signUp: "signUp",
    loading: "loading",
    defaultCredential: "defaultCredential",
    accountFound: "accountFound"
  };
  public activeSlide: any = this.slides.loading;
  public isLoginWithEmail: boolean;
  public defaultCredentialUser: User;

  public existingAccount: any = null
  public existingAccountFoundAgainst: 'phone' | 'email' = null

  constructor(
    private userService: UserService,
    private store: Store,
    private authenticationService: AuthenticationService,
    private otpService: OtpService,
    private alertController: AlertController,
    private accountProfileFacade : AccountProfileFacade
  ) {
    this.createUserDto.phoneVerifier = new OtpVerifier(OtpChannel.phone);
    this.createUserDto.emailVerifier = new OtpVerifier(OtpChannel.email);
  }

  ngOnInit() {
    this.checkLoginStatus();
    this.isLoginWithEmail = this.isEmailRequired || false
  }

  checkLoginStatus() {
    this.authenticationService.checkPreviousAuthenticatedUser().then(
      token => {
        this.onLoginStatusChecked(token)
      }
    )
  }

  async onLoginStatusChecked(token: string) {

    const isDefaultCredentials = ValidationUtil.isEmailValid(this.defaultEmail)

    if (!token && !isDefaultCredentials) {
      return this.goToSlide(this.slides.signIn);
    }

    if (!token && isDefaultCredentials) {
      return this.loginFlowForDefaultCredential();
    }

    if (token && !isDefaultCredentials) {
      return this.goToSlide(this.slides.loggedIn);
    }

    if (token && isDefaultCredentials) {

      this.authenticatedUser$.subscribe((user) => {
        if (!user) {
          return
        }
        if (user.email == this.defaultEmail) {
          return this.goToSlide(this.slides.loggedIn)
        }
        this.loginFlowForDefaultCredential()
      })


    }

  }

  async loginFlowForDefaultCredential() {

    this.createUserDto.emailVerifier.receiverId = this.defaultEmail

    const checkEmailUser = await this.userService.checkEmail(this.defaultEmail);

    if (checkEmailUser) {
      this.defaultCredentialUser = checkEmailUser
      this.goToSlide(this.slides.defaultCredential);
      return
    }

    this.createUserDto.name = this.defaultName

    await this.quickSignup(false)


  }

  async useOtherAccount() {
    await this.authenticationService.signOut();
    this.goToSlide(this.slides.signIn);
  }

  public goToSlide(slide: string) {
    this.activeSlide = slide;
  }

  public quickSignup = async (emitLogin = true) => {

    ValidationUtil.validateName(this.createUserDto.name);

    await this.checkEmail();

    await this.checkPhoneNumber();

    this.createUserDto.name = this.createUserDto.name.trim()

    const isLoggedIn: boolean = await this.authenticationService.quickSignUp(this.createUserDto);

    if (emitLogin && isLoggedIn) {
      await this.login();
    }
  };

  public onEmail = async () => {
    if (
      !ValidationUtil.isEmailValid(this.createUserDto.emailVerifier.receiverId)
    ) {
      return;
    }

    await this.authenticationService.signInAnonymouslyIfNoUser();

    const user = await this.userService.checkEmail(
      this.createUserDto.emailVerifier.receiverId
    );

    if (!user) {
      this.activeSlide = this.slides.signUp;
      return;
    }

    return this.sendOtpAndLogin(this.createUserDto.emailVerifier);
  };

  public sendOtpAndLogin = async (otpVerifier: OtpVerifier) => {

    const res = await this.authenticationService.signInWithOtp(otpVerifier.receiverId, otpVerifier.channel)

    if (res) {
      return this.login();
    }

  };

  public async login() {
    return this.emitLogin();
  }


  public onContinueAsLoggedInUserClicked = async () => {
    return this.emitLogin();
  }

  emitLogin = async () => {
    return this.onLogin.emit();
  }

  public onContinueWithPhone = async () => {

    ValidationUtil.validatePhone(this.createUserDto.phoneVerifier.receiverId)

    await this.authenticationService.signInAnonymouslyIfNoUser();

    const user = await this.userService.isPhoneRegistered(this.createUserDto.phoneVerifier.receiverId)

    if (!user) {
      return this.signUp(this.createUserDto.phoneVerifier)
    }

    const logInSuccess = await this.authenticationService.signInWithOtp(this.createUserDto.phoneVerifier.receiverId, OtpChannel.phone)

    if (logInSuccess) {
      return this.login();
    }

  };

  public onContinueWithEmail = async () => {

    const emailInput = this.createUserDto.emailVerifier.receiverId;

    ValidationUtil.validateEmail(emailInput)

    await this.authenticationService.signInAnonymouslyIfNoUser();

    const user = await this.userService.checkEmail(emailInput)

    if (!user) {
      return this.signUp(this.createUserDto.emailVerifier)
    }
    const logInSuccess = await this.authenticationService.signInWithOtp(emailInput, OtpChannel.email)

    if (logInSuccess) {
      return this.login();
    }

  };

  signUp = async (otpVerifier: OtpVerifier) => {

    if (this.isOtpVerification) {
      const otpRes = await this.otpService.sendAndVerify(otpVerifier)
      if (!otpRes?.isVerified) {
        return;
      }
    }

    return this.goToSlide(this.slides.signUp)
  }


  // Helper functions

  async checkEmail() {

    const emailInput = this.createUserDto.emailVerifier.receiverId;

    if (this.isEmailRequired && ValidationUtil.isStringEmpty(emailInput)) {
      throw new Error("Enter an Email Address")
    }

    if(ValidationUtil.isStringEmpty(emailInput)){
      return
    }

    if (!ValidationUtil.isEmailValid(emailInput)) {
      throw new Error("Enter a Valid Email Address")
    }

    const emailUser = await this.userService.checkEmail(emailInput);

    if (emailUser) {
      this.existingAccountFoundAgainst = "email"
      this.existingAccount = emailUser;
      this.goToSlide(this.slides.accountFound)
      throw new Error("Email already exists")
    }

  }

  async checkPhoneNumber() {

    const phoneInput = this.createUserDto.phoneVerifier.receiverId;

    if (this.isPhoneRequired && ValidationUtil.isStringEmpty(phoneInput)) {
      throw new Error("Enter a Phone Number")
    }

    if(ValidationUtil.isStringEmpty(phoneInput)){
      return
    }

    if (!ValidationUtil.isValidPhone(phoneInput)) {
      throw new Error("Enter a Valid Phone Number")
    }

    const phoneUser = await this.userService.isPhoneRegistered(phoneInput);

    if (phoneUser) {
      this.existingAccountFoundAgainst = "phone"
      this.existingAccount = phoneUser;
      this.goToSlide(this.slides.accountFound)
      throw new Error("Phone number already exists")
    }

  }


  // UI Helper functions

  switchToContinueWithEmail() {
    this.createUserDto.phoneVerifier = new OtpVerifier(OtpChannel.phone);
    this.isLoginWithEmail = true;
  }

  switchToContinueWithPhone() {
    this.createUserDto.emailVerifier = new OtpVerifier(OtpChannel.email);
    this.isLoginWithEmail = false;
  }

  onExistingEmailOtpClicked = async () => {

    if (this.createUserDto.emailVerifier.receiverId && this.existingAccountFoundAgainst == "email") {
      return this.onContinueWithEmail()
    }
    this.goToSignInSlide()
  }

  onExistingPhoneOtpClicked = async () => {
    if (this.createUserDto.phoneVerifier.receiverId && this.existingAccountFoundAgainst == "phone") {
      return this.onContinueWithPhone()
    }
    this.goToSignInSlide()
  }

  goToSignInSlide() {
    this.createUserDto.phoneVerifier = new OtpVerifier(OtpChannel.phone);
    this.createUserDto.emailVerifier = new OtpVerifier(OtpChannel.email);
    this.createUserDto.name = null
    this.goToSlide(this.slides.signIn)
  }
}
