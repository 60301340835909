<div sxCol sxGap="10px" sxPadding="medium" sxBackground="light" sxRadius="medium">

  <div *ngIf="authenticatedUser$ | async as user" sxRow sxGap="10px" sxAlign="center" sxDistribution="spaceBetween"
       sxBorderColor="one" sxBorderWidth="0 0 1px">
    <div sxRow sxGap="10px" sxAlign="center">
      <avatar-round [imageUrl]="participant?.account?.displayPictureUrl" [title]="participant?.account?.name"
                    [size]="30"></avatar-round>
      <p sxCapitalize>{{participant?.account?.name?.toLowerCase()}}<span sxColor="two" sxTextSize="small"
                                                                         sxMargin="0 5px">{{participant.account_id == user._id && template.allowGroup ? '(You)' : ""}}</span>
      </p>
    </div>
    <div sxRow sxAlign="center" *ngIf="deleteButton">
      <super-button padding="0" height="fit" type="clear" [action]="removeParticipant" confirmationTitle="Remove">
        <ion-icon *ngIf="participant.account_id != user._id" name="close-circle-outline"
                  sxTextSize="30px"></ion-icon>
      </super-button>

    </div>
  </div>


  <div *ngFor="let field of participant.mandatoryIds">
    <p sxMargin="15px 0 10px 8px">{{field.label}}</p>
    <id-uploader
      [field]="field"
      [account]="participant?.account"
      (onIdUpload)="onIdUpload()"
    ></id-uploader>
  </div>


  <div sxRow sxDistribution="spaceBetween" sxPadding="15px 8px 0" *ngIf="participant.optionalIds?.length > 0">
    <p sxMargin="0" sxTextSize="medium">{{selectedParticipantOptionalId?.label}}</p>
    <div class="drop-down-wrapper" sxWidth="120px">
      <div sxRow sxPosition="absolute" sxAlign="center" sxGap="5px" (click)="onOptionIdDropdownClicked()">
        <p sxMargin="0" sxColor="two" sxTextSize="small">Choose other</p>
        <ion-icon sxTextSize="14px" sxColor="two" name="caret-down-outline"></ion-icon>
      </div>
      <select sxCursor="pointer" [(ngModel)]="selectedParticipantOptionalId" sxPosition="absolute" #optionalIdSelect>
        <option *ngFor="let option of participant.optionalIds" [ngValue]="option">{{option.label}}</option>
      </select>
    </div>

  </div>

  <id-uploader
    *ngIf="selectedParticipantOptionalId"
    sxWidth="fill"
    [field]="selectedParticipantOptionalId"
    [account]="participant?.account"
    (onIdUpload)="onIdUpload()"
  ></id-uploader>
</div>
