import {NgModule} from "@angular/core";
import {AccountProfileViewComponent} from "./account-profile-view/account-profile-view.component";
import {SuperModule} from "@qid/super";
import {SexyModule} from "@qid/sexy";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {ShareAccountProfileViewComponent} from "./share-account-profile-view/share-account-profile-view.component";

@NgModule({
  declarations: [AccountProfileViewComponent,
    ShareAccountProfileViewComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SuperModule,
    SexyModule
  ],
  exports: [AccountProfileViewComponent,
    ShareAccountProfileViewComponent]
})

export class AccountProfileComponentsModule {

}
