export enum AccountAttributeType {

  'fullName' = 'fullName',
  'email' = 'email',
  'phone' = 'phone',
  'gender' = 'gender',
  'addressLine' = 'addressLine',
  'dob' = 'dob',
  'displayPicture' = 'displayPicture',
  'address' = 'address',
}
