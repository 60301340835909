import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {FaceIdApi} from "../../modules/face-id";
import {FaceCapture} from '../face-capture/face-capture.component';
import {DisplayService, ImageUtil} from "@qid/core";
import {FaceId} from "../../modules/face-id/model/face-id.model";
import {DigilockerComponent} from "../digilocker/digilocker.component";
import {AccountProfileFacade, AccountProfileStore} from "../../modules/account-profile";
import {Store} from '@ngrx/store';
import {firstValueFrom} from 'rxjs';
import {AuthSelectors} from "../../modules/authentication";
import {AccountProfileApi} from "../../modules/account-profile/services/account-profile.api";
import {UsersFacade} from "../../modules/user";
import {ID} from "../../models/ID";
import {IdService} from "../../modules/id";
import {DigilockerRequestType} from "../../enum/digilocker-request-type";
import {DigilockerRequest, DigilockerService} from "../../modules/digilocker-request";
import {RequestStatus} from "../../enum/request-status.enum";
import {AccountAttributeFacade} from "../../modules/account-attribute";


@Component({
  selector: 'verified-signup',
  templateUrl: './verified-signup.component.html',
  styleUrls: ['./verified-signup.component.scss'],
})
export class VerifiedSignupComponent {

  @ViewChild('faceCapture') faceCapture: FaceCapture
  @ViewChild('digilockerComponent') digilockerComponent: DigilockerComponent
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  public faceId: FaceId = null;
  public isFetchingFaceId = true;
  public idVerificationStatus: 'started' | 'success' | 'failed' = null;

  public activeAccount$ = this.store.select(AuthSelectors.selectActiveAccount);
  public profile$ = this.profileFacade.profile$

  public verifiedAadhaar: ID = null

  public isProfileSlide = false;
  public isCreatingProfile = false

  public digilockerUrl = null
  public digilockerRequest = null

  public accountAttributes$ = this.accountAttributeFacade.allAttributes$


  constructor(private faceIdApi: FaceIdApi,
              private profileFacade: AccountProfileFacade,
              private store: Store,
              private profileApi: AccountProfileApi,
              private displayService: DisplayService,
              private userFacade: UsersFacade,
              private documentService: IdService,
              private digilockerService : DigilockerService,
              private accountAttributeFacade : AccountAttributeFacade
  ) {
  }

  ionViewWillEnter() {
    this.init()

  }

  async init() {

    const isProfileExists = await this.checkIfProfileExists()

    if (isProfileExists) {
      const profile = await firstValueFrom(this.profileFacade.profile$)
      await this.accountAttributeFacade.init()
      const attributes = await firstValueFrom(this.accountAttributeFacade.allAttributes$)
      console.log(profile)
      console.log(attributes)
      this.isProfileSlide = true;
      // this.onSubmit.emit();
      return
    }

    this.isFetchingFaceId = true;
    const res = await this.faceIdApi.read();
    this.isFetchingFaceId = false;
    if (res && res.data) {
      this.faceId = res.data;
      this.onFaceIdConfirmed();
    }
  }


  async checkIfProfileExists(): Promise<boolean> {
    await this.profileFacade.init()
    return new Promise((resolve) => {
      this.store.select(AccountProfileStore.selectors.selectActiveAccountAdditionalData)
        .subscribe(data => {
          if (!data.isProfileFetched) {
            return
          }
          return resolve(
            data.profile != null);
        })
    })

  }

  onFaceCaptured(data: {  imageData: string, isLive: boolean }) {
    let image: any = {dataUrl: data.imageData}
    const file = ImageUtil.dataURLtoFile(
      image.dataUrl,
      new Date().getTime() + '.' + image.format
    );

    this.createFaceId(file)
  }

  private async createFaceId(file: File) {
    const res = await this.faceIdApi.create(file)
    if (res && res.data) {
      this.faceId = res.data
    }
    if (this.faceId) {
      this.onFaceIdConfirmed()
    }
  }


  onFaceIdConfirmed() {
    this.goToIdVerification();
  }

  async goToIdVerification() {
    const ids = await this.documentService.readAll()
    const verifiedAadhaar = ids.find(id => id.sid.keyName == "ADHAR" && id.verificationTime != null)

    if (verifiedAadhaar) {
      // this.verifiedAadhaar = verifiedAadhaar;
      // return this.createProfile()
    }

    const account = await firstValueFrom(this.activeAccount$)
    await this.createDigilockerRequest()
  }

  createProfile = async () => {
    this.isProfileSlide = true;
    this.isCreatingProfile = true;
    const res = await this.profileApi.create()
    this.isCreatingProfile = false;
    if (res && res.data) {
      await this.profileFacade.refresh()
      this.userFacade.refresh()
      this.displayService.toast({
        message: "Verification successful",
        color: 'success',
        position: 'top'
      })
      // this.onSubmit.emit();
    }
  }

  onFaceChangeClicked = async () => {
    await this.faceIdApi.delete();
    this.faceId = null;
    this.verifiedAadhaar = null;
  }


  async createDigilockerRequest() {
    const res = await this.digilockerService.getUrl({
      runFaceMatch: true,
      requestType : DigilockerRequestType.personalAccountVerification
    })
    if (!res || !res.data) {
      throw res.errorMessage
    }
    this.digilockerUrl = res.data.url;
    this.digilockerRequest = res.data.digilockerRequest
  }


  openDigilockerLink = async () => {
    this.idVerificationStatus = 'started'
    window.open(this.digilockerUrl, '_blank');
    this.idVerificationStatus = 'started'
    // this.progressMessage = "Waiting"
    this.setDigilockerRequestStatusCheckInterval()
  }

  public digilockerStatusCheckInterval = null

  setDigilockerRequestStatusCheckInterval() {

    this.digilockerStatusCheckInterval = setInterval(async () => {

      const res = await this.digilockerService.findById(this.digilockerRequest._id)
      if (res?.data) {
        this.onDigilockerRequestFetched(res.data)
      }

    }, 2000)

  }

  private onDigilockerRequestFetched(request: DigilockerRequest) {

    this.digilockerRequest = request

    if (this.digilockerRequest.status === RequestStatus.failed) {
      clearInterval(this.digilockerStatusCheckInterval)
      this.idVerificationStatus = 'failed'
    }
    if (this.digilockerRequest.status == RequestStatus.completed) {
      clearInterval(this.digilockerStatusCheckInterval)
      this.onDigilockerRequestSuccess();
    }
  }

  private async onDigilockerRequestSuccess() {
    this.idVerificationStatus = 'success'
    this.createProfile()
  }

  onShareConfirmed(sharedProfile) {
    this.onSubmit.emit(sharedProfile)
  }
}
