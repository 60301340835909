import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {UploadImageButtonComponent} from '../upload-image-button/upload-image-button.component';
import {CameraSource} from '@capacitor/camera';
import {Platform} from '@ionic/angular';
import {ImageUtil} from "@qid/core";

@Component({
  selector: 'avatar-round',
  templateUrl: './avatar-round.component.html',
  styleUrls: ['./avatar-round.component.scss'],
})
export class AvatarRoundComponent implements OnInit {
  @ViewChild('uploadImageButtonComponent')
  uploadImageButtonComponent: UploadImageButtonComponent;

  private defaultImageUrl = 'assets/imgs/user-image-placeholder.png';
  @Input() imageUrl;
  @Input() autoBase = true;
  @Input() size = 100;
  @Input() lock = false;
  @Input() bubbleSize = 40;
  @Input() isEditMode: boolean = false;
  @Input() onImageAction: any;
  @Input() title: string;
  @Input() bubbleFilled: boolean = false;
  @Input() bubbleIcon: string = "camera-outline";
  @Output() onBubbleClick = new EventEmitter();
  @Output() onImage = new EventEmitter();
  backgroundColor;
  isOnAction: boolean = false;

  constructor(private platform: Platform) {
  }

  ngOnInit() {
    this.backgroundColor = this.getDarkColor()
  }

  getBubbleTop() {
    return this.size - this.bubbleSize / 2;
  }

  getBubbleLeft() {
    return this.size / 2 - this.bubbleSize / 2;
  }

  onBubbleClicked() {
    this.onBubbleClick.emit();
  }

  onCameraClicked() {

    if(this.onBubbleClick){
      this.onBubbleClick.emit();
      return
    }

    if (this.platform.is('desktop')) {
      this.uploadImageButtonComponent.upload(CameraSource.Photos);
      return;
    }

    this.uploadImageButtonComponent.upload(CameraSource.Prompt);
  }

  async onImageLoad(file: any) {
    this.isOnAction = true;
    try {
      await this.onImageAction(file);
      this.isOnAction = false;
    } catch (e) {
      this.isOnAction = false;
      console.error(e);
    }
  }

  getUrl() {
    if (!this.imageUrl || this.imageUrl.length < 1) {
      return this.defaultImageUrl;
    }
    if (!this.autoBase) {
      return this.imageUrl;
    }
    return ImageUtil.getImageSource(this.imageUrl);
  }

  getInitials(title: string) {
    if (!title) {
      return null;
    }
    let parts = title.split(' ');
    return parts.length > 1
      ? parts[0].charAt(0) + parts[1].charAt(0)
      : parts[0].charAt(0);
  }

  getInitialsFontSize() {
    return +this.size - this.size * 0.6 + 'px';
  }

  getDarkColor() {
    // let color = '#';
    // for (let i = 0; i < 6; i++) {
    //   color += Math.floor(Math.random() * 10);
    // }
    return 'dark';
  }
}
